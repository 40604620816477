import store from '../store'
import { SHOW_SUCCESS_MESSAGEBOX, SHOW_WARNING_MESSAGEBOX, SHOW_ERROR_MESSAGEBOX, SHOW_MESSAGEBOX, CLEAR_MESSAGEBOX } from '../store/actionTypes'
export default class MessageBox {
    static Error(message, title, options) {
        let additionalOptions = MessageBoxHelper.checkOptions(options);
        let payload = { text: message, title: title ?? "Kritik Hata", ...additionalOptions };
        store.dispatch({ type: SHOW_ERROR_MESSAGEBOX, payload: payload });
    }

    static Success(message, title, options) {
        let additionalOptions = MessageBoxHelper.checkOptions(options);
        let payload = { text: message, title: title ?? "Kritik Hata", ...additionalOptions };
        store.dispatch({ type: SHOW_SUCCESS_MESSAGEBOX, payload: payload });
    }

    static Warning(message, title, options) {
        let additionalOptions = MessageBoxHelper.checkOptions(options);
        let payload = { text: message, title: title ?? "Kritik Hata", ...additionalOptions };
        store.dispatch({ type: SHOW_WARNING_MESSAGEBOX, payload: payload });
    }

    static Default(message, title, options) {
        let additionalOptions = MessageBoxHelper.checkOptions(options);
        let payload = { text: message, title: title ?? "Kritik Hata", ...additionalOptions };
        store.dispatch({ type: SHOW_MESSAGEBOX, payload: payload });
    }

    static Clear() {
        let additionalOptions = MessageBoxHelper.checkOptions({});
        additionalOptions.show = false;
        let payload = { text: "", title: "", ...additionalOptions };
        store.dispatch({ type: CLEAR_MESSAGEBOX, payload: payload });
    }
}

class MessageBoxHelper {
    static checkOptions(options) {
        let result = {};
        if (options && options.okText) {
            result.okText = options.okText;
        }
        if (options && options.closeText) {
            result.closeText = options.closeText;
        }
        if (options && options.confirm) {
            result.confirm = options.confirm;
        }
        if (options && options.html) {
            result.html = options.html;
        }
        return result;
    }
}