import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'

class MessageBox extends Component {
    render() {
        return (
            <Modal show={this.props.show} size={null} onHide={this.props.close} centered backdrop="static">
                <Modal.Header closeButton className={"bg-" + this.props.type}>
                    <Modal.Title as="h5" className={this.props.type === 'default' ? '' : 'text-white'}>
                        {this.props.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.props.html && this.props.html.length > 0 && <div dangerouslySetInnerHTML={{ __html: this.props.html }} ></div>}
                    {this.props.text && this.props.text.length > 0 && <span>{this.props.text}</span>}                    
                </Modal.Body>
                <Modal.Footer>
                    {this.getCloseButton()}
                    <Button variant={this.props.type} onClick={() => { this.props.confirm(); this.props.close(); }}>{this.props.okText}</Button>
                </Modal.Footer>
            </Modal>
        )
    }

    getCloseButton() {
        if (this.props.closeText) {
            return (<Button variant="default" onClick={this.props.close}>{this.props.closeText}</Button>);
        }
    }

    confirm(){
        this.props.confirm();
        this.props.close();
    }
}

export default MessageBox
