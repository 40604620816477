const SHOW_SUCCESS_MESSAGEBOX = 'SHOW_SUCCESS_MESSAGEBOX';
const SHOW_ERROR_MESSAGEBOX = 'SHOW_ERROR_MESSAGEBOX';
const SHOW_WARNING_MESSAGEBOX = 'SHOW_WARNING_MESSAGEBOX';
const SHOW_MESSAGEBOX = 'SHOW_MESSAGEBOX';
const CLEAR_MESSAGEBOX = 'CLEAR_MESSAGEBOX';
const GET_USER_SESSION = 'GET_USER_SESSION';
const SET_APP_SEARCH = 'SET_APP_SEARCH';
const GET_LDAP_USER = 'GET_LDAP_USER';
const SHOW_BLOCKUI = 'SHOW_BLOCKUI';

export {
    SHOW_SUCCESS_MESSAGEBOX,
    SHOW_ERROR_MESSAGEBOX,
    SHOW_WARNING_MESSAGEBOX,
    SHOW_MESSAGEBOX,
    CLEAR_MESSAGEBOX,
    GET_USER_SESSION,
    SET_APP_SEARCH,
    GET_LDAP_USER,
    SHOW_BLOCKUI
};