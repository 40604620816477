import { toast } from 'react-toastify'
export default class Notify {
    static Error(message) {
        toast.error(message);
    }

    static Success(message) {
        toast.success(message);
    }

    static Warning(message) {
        toast.warn(message);
    }
}