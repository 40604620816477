import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { Modal, Button, Navbar, Nav, Dropdown, Row, Col, Card } from 'react-bootstrap'
import layoutHelpers from './helpers'
import store from '../../store'
import { Form, Image } from 'react-bootstrap'
import { SET_APP_SEARCH } from "../../store/actionTypes";
import dashboardService from '../../pages/management/services/dashboardService'

class LayoutNavbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      personalInformationVisible: false,
      settingsVisible: false,
      notificationsVisible: false,
      startDate: [],
      startDate1: [],
      profile: {
        firstName: "",
        lastName: "",
        eMail: "",
        phone: ""
      },
      notifications: []
    };

    this.isRTL = document.documentElement.getAttribute('dir') === 'rtl'
  }

  componentDidMount() {
    dashboardService.getProfile(d => {
      this.setState({
        profile: {
          firstName: d.firstName || "",
          lastName: d.lastName || "",
          eMail: d.mail || "",
          phone: d.phone || ""
        }
      })
    })
    this.loadNotifications();
  }

  loadNotifications() {
    dashboardService.loadNotifications(d => {
      let notify = d.map(m => {
        return {
          imageUrl: m.iconData ? "data:image/png;base64," + m.iconData : `${process.env.PUBLIC_URL}/img/bg/logo.png`,
          title: m.title,
          description: m.description,
        }
      });
      this.setState({
        notifications: [...notify]
      }
      );
    });
  }

  toggleSidenav(e) {
    e.preventDefault()
    layoutHelpers.toggleCollapsed()
  }

  logout() {
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = process.env.REACT_APP_AUTHSERVER + "/Account/LogOutWithRedirectUrl?redirectUrl=" + process.env.REACT_APP_HOSTURL;
  }

  onSearchChanged(e) {
    this.setState({ search: e.target.value }, () => {
      store.dispatch({ type: SET_APP_SEARCH, payload: this.state.search });
    });
  }

  onSearchCleared(e) {
    if (e.keyCode === 27) { //ESCAPE
      this.setState({ search: "" }, () => {
        store.dispatch({ type: SET_APP_SEARCH, payload: "" });
      });
    }
  }

  openSettings() {
    this.props.history.push("/settings");
  }

  openLogins() {
    this.props.history.push("/loginIndex");
  }

  showModal(feature) {
    this.setState({ [feature + "Visible"]: true });
  }

  hideModal(feature) {
    this.setState({ [feature + "Visible"]: false })
  }

  renderPersonalInformation() {
    return (
      <React.Fragment>
        <Modal className="modal" size="lg" show={this.state.personalInformationVisible} onHide={() => this.hideModal("personalInformation")}>
          <Modal.Header closeButton>
            <Modal.Title>Kişisel Bilgiler</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group as={Row}>
              <Form.Label column sm="2">Adı</Form.Label>
              <Col sm="10">
                <Form.Control readOnly value={this.state.profile.firstName} />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="2">Soyadı</Form.Label>
              <Col sm="10">
                <Form.Control readOnly value={this.state.profile.lastName} />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="2">E-Posta Adresi</Form.Label>
              <Col sm="10">
                <Form.Control readOnly value={this.state.profile.eMail} />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm="2">Telefon</Form.Label>
              <Col sm="10">
                <Form.Control readOnly value={this.state.profile.phone} />
              </Col>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button id="btnClose" variant="primary" onClick={() => this.hideModal("personalInformation")}>Kapat</Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    )
  }

  renderNotifications() {
    return (
      <React.Fragment>
        <Modal className="modal" size="lg" show={this.state.notificationsVisible} onHide={() => this.hideModal("notifications")}>
          <Modal.Header closeButton>
            <Modal.Title>Bildirimler</Modal.Title>
          </Modal.Header>
          <Modal.Body >
            <div className='scroll'>
              {this.state.notifications.map((x, index) => (
                <Card className='mb-4' style={{ backgroundColor: index % 2 === 0 ? "#E0E0E0" : "#FFFFFF" }}>
                  <Card.Body>
                    < Row >
                      <Col md={3}>
                        <Image
                          src={x.imageUrl}
                          alt="Profile"
                          className="d-block ui-w-140"
                        />
                      </Col>
                      <Col md={9}>
                        <Form.Row>
                          <Form.Group as={Col} md={12}>
                            <Form.Label style={{ fontWeight: "bold", fontSize: "18px" }}>{x.title}</Form.Label>
                            <Form.Control id="description" as='textarea' rows="4" value={x.description} readOnly />
                          </Form.Group>
                        </Form.Row>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              ))
              }
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button id="btnClose" variant="primary" onClick={() => this.hideModal("notifications")}>Kapat</Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment >
    )
  }

  render() {
    return (
      <div>
        <Navbar expand="lg" className="layout-navbar align-items-lg-center container-p-x navbar navbar-expand-lg bg-danger-darker navbar-dark">
          <Navbar.Brand as={NavLink} to="/" className="app-brand demo d-lg-none py-0 mr-4">
            <span className="app-brand-logo demo bg-white">
              <img src="/img/logo.png" alt="Sağlık Bakanlığı" />
            </span>
            <span className="app-brand-text demo font-weight-normal ml-2">Ortak Giriş Noktası</span>
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse>
            {/* Divider */}
            <hr className="d-lg-none w-100 my-2" />
            <Nav className="align-items-lg-center ml-auto">
              <Form.Control placeholder="Ara" value={this.state.search} onChange={(e) => this.onSearchChanged(e)} onKeyDown={(e) => this.onSearchCleared(e)}></Form.Control>
              <Dropdown as={Nav.Item} className="demo-navbar-user" alignRight={!this.isRTL}>
                <Dropdown.Toggle as={Nav.Link}>
                  <span id="btnUser" className="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
                    <i className="far fa-user"></i>
                    <span className="px-1 mr-lg-2 ml-2 ml-lg-0">{store.getState().session.displayName}</span>
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => this.showModal("personalInformation")}><i id="btnMypage" className="far fa-user text-lightest"></i> &nbsp; Kişisel Bilgiler</Dropdown.Item>
                  <Dropdown.Item onClick={() => this.openLogins()}><i id="btnLogins" className="fas fa-users text-lightest"></i> &nbsp;Girişler</Dropdown.Item>
                  <Dropdown.Item onClick={() => this.openSettings()}><i id="btnSettings" className="fas fa-cog text-lightest"></i> &nbsp; Ayarlar</Dropdown.Item>
                  <Dropdown.Divider></Dropdown.Divider>
                  <Dropdown.Item onClick={() => this.showModal("notifications")}><i id="btnNotifications" className="far fa-bell text-lightest"></i> &nbsp; Bildirimler</Dropdown.Item>
                  <Dropdown.Divider></Dropdown.Divider>
                  <Dropdown.Item onClick={() => this.logout()}><i id="btnLogout" className="fas fa-sign-out-alt text-lightest"></i> &nbsp; Çıkış Yap</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        {this.renderPersonalInformation()}
        {this.renderNotifications()}
      </div>
    )
  }
}

LayoutNavbar.propTypes = {
  sidenavToggle: PropTypes.bool
}

LayoutNavbar.defaultProps = {
  sidenavToggle: true
}

export default LayoutNavbar;
