import ajax from '../helpers/ajax'
import MessageBox from '../helpers/messageBox'
import Notify from '../helpers/Notify'
import { encode } from 'html-entities';
import auth from '../helpers/authenticate'
import store from '../store';
import { SHOW_BLOCKUI } from "../store/actionTypes";

class ServiceBase {
  constructor(controllerUrl) {
    this.controller = controllerUrl;
  }

  get(path, callback) {
    addLoadingClass();
    return ajax.get(this.controller.concat("/", path)).then(response => {
      if (response.data) {
        callback(response.data);
      }
      else {
        Notify.Warning("Beklenmeyen bir durum oluştu");
      }
    }).catch(error => {
      handleException(error);
    }).finally(() => {
      removeLoadingClass();
    });
  }

  post(path, data, callback, errorCallback) {
    addLoadingClass();
    return ajax.post(this.controller.concat("/", path), data).then(d => {
      callback(d.data);
    }).catch(error => {
      handleException(error);
      if (errorCallback) {
        errorCallback(error);
      }
    }).finally(() => {
      removeLoadingClass();
    });
  }

  put(path, data, callback) {
    addLoadingClass();
    return ajax.put(this.controller.concat("/", path), data).then(d => {
      if (d.data.state === 0) {
        callback(d.data.result);
      }
    }).catch(error => {
      handleException(error);
    }).finally(() => {
      removeLoadingClass();
    });
  }

  delete(path, data, callback) {
    addLoadingClass();
    return ajax.delete(this.controller.concat("/", path), data).then(d => {
      if (d.data.state === 0) {
        callback(d.data.result);
      }
    }).catch(error => {
      handleException(error);
    }).finally(() => {
      removeLoadingClass();
    });
  }

  getFile(path) {
    return ajax.getFile(this.controller.concat('/', path)).then(response => {
      return URL.createObjectURL(response.data);
    });
  }
}
const addLoadingClass = () => {
  const block = {
    type: SHOW_BLOCKUI,
    payload: true,
  };
  store.dispatch(block);
}

const removeLoadingClass = () => {
  setTimeout(() => {
    const block = {
      type: SHOW_BLOCKUI,
      payload: false,
    };
    store.dispatch(block);
  }, 300);
}

const handleException = (error) => {
  debugger;
  if (!error.response) {
    MessageBox.Error('Beklenmeyen bir hata oluştu.');
    return;
  }
  console.log(error.response);
  if (error.response.status === 401) {
    MessageBox.Error(
      'Oturumunuz sonladı. Giriş sayfasına yönlendiriliyorsunuz.'
    );
    setTimeout(() => {
      auth.authenticate(true, (result) => {
        window.location.href = "/";
      });
    }, 3000);
    return;
  }
  if (error.response.status === 403) {
    MessageBox.Warning(
      'Yapmaya çalıştığınız işlem için yetkiniz bulunmamaktadır'
    );
    return;
  }
  if (error.response?.data?.type === 'UnauthorizedAccessException') {
    MessageBox.Warning(
      'Yapmaya çalıştığınız işlem için yetkiniz bulunmamaktadır'
    );
    return;
  }
  let title = error.response.data && error.response.data.title && error.response.data.title.length > 0 ? error.response.data.title : "Hata";
  let errorCode = error.response.data && error.response.data.errorCode && error.response.data.errorCode.length > 0 ? error.response.data.errorCode : null;
  let exceptionId = error.response.data && error.response.data.exceptionId && error.response.data.exceptionId.length > 0 ? error.response.data.exceptionId : null;
  let exceptionLevel = error.response.data && error.response.data.exceptionLevel ? error.response.data.exceptionLevel : 1;
  let message = error.response.data && error.response.data.message && error.response.data.message.length > 0 ? error.response.data.message : "İşlem sırasında beklenmeyen bir hata oluştu."
  if (error.response.data && error.response.data.errors) {
    message = "";
    for (let key in error.response.data.errors) {
      message += "<h5>" + encode(key) + "</h5>";
      message += "<ul>";
      let messageList = error.response.data.errors[key];
      // eslint-disable-next-line
      messageList.forEach(msg => {
        message += "<li>" + encode(msg) + "</li>";
      });
      message += "</ul>";
    }
  }
  if (exceptionLevel !== 3 && errorCode) {
    message += "<hr><p><code><small>HATA KODU : " + encode(errorCode) + "</small></code></p>";
  }
  if (exceptionLevel !== 3 && exceptionId) {
    message += "<hr><p><code><small>HATA ID : " + encode(exceptionId) + "</small></code></p>";
  }
  MessageBox.Error(null, title, { html: message });
};

export default ServiceBase;