import React, { Component } from "react"
import { Jumbotron } from 'react-bootstrap';
import LayoutNavbar from './LayoutNavbar'
import LayoutFooter from './LayoutFooter'
import LayoutCookie from './LayoutCookie'
import layoutHelpers from './helpers'
import MessageBox from '../alerts/MessageBox'
import { ToastContainer } from 'react-toastify'
import BlockUI from "../../components/BlockUi"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import '../../vendor/libs/react-toastify/react-toastify.scss'
import store from '../../store';
import auth from '../../helpers/authenticate'
import MessageBoxHelper from "../../helpers/messageBox";

const CloseButton = ({ closeToast }) => (<button className="Toastify__close-button" type="button" aria-label="close" onClick={closeToast}>&times;</button>)
class Layout extends Component {
  _subscription = null;
  constructor(props) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
    this.state = {
      isModalVisible: false,
      modalText: '',
      modalTitle: '',
      modalType: '',
      modalCloseText: '',
      modalOkText: '',
      modalHtml: null,
      modalConfirm: null,
      signedIn: false,
      search: "",
      blocking: false
    };
  }

  closeSidenav(e) {
    e.preventDefault();
    layoutHelpers.setCollapsed(true);
  }

  componentDidMount() {
    auth.authenticate(false, (result) => {
      this.setState({ signedIn: result.signedIn });
      if (!result.signedIn) {
        alert("Kullanıcı giriş işlemi başarısız");
        return;
      }
      this.subscription = store.subscribe(() => {
        let state = store.getState();
        let mBox = state.messageBox;
        let block = state.blockUi;
        this.setState({
          isModalVisible: mBox.show,
          modalText: mBox.text,
          modalHtml: mBox.html,
          modalTitle: mBox.title,
          type: mBox.type,
          modalOkText: mBox.okText,
          modalCloseText: mBox.closeText,
          modalConfirm: mBox.confirm,
          blocking: block
        });
      });
    });

    layoutHelpers.init();
    layoutHelpers.setAutoUpdate(true);
    layoutHelpers.update();
  }

  componentWillUnmount() {
    layoutHelpers.destroy();
    this.subscription();
  }

  closeModal() {
    this.setState({ isModalVisible: false });
    MessageBoxHelper.Clear();
  }

  renderDependingOnAuthentication() {
    if (this.state.signedIn) {
      return (<div className="layout-inner py-2">
        <BlockUI blocking={this.state.blocking}></BlockUI>
        <LayoutNavbar {...this.props} />
        <div className="layout-container">
          <div className="layout-content">
            <div className="container flex-grow-1 container-p-y">
              {this.props.children}
            </div>
            <LayoutFooter {...this.props} />
            <LayoutCookie ></LayoutCookie>
          </div>
        </div>
      </div>)
    } else {
      return (
        <Jumbotron className="position-fixed mb-0 zero">
          <h1 className="display-3">Oturumunuz Kontrol Ediliyor</h1>
          <p className="lead">Giriş bilgileriniz ve oturum kontrolleriniz yapılıyor. Bu işlem uzun sürerse sistem yöneticisine başvurunuz.</p>
        </Jumbotron>)
    }
  }

  render() {
    return (
      <div className="layout-wrapper layout-1 layout-without-sidenav">
        {this.renderDependingOnAuthentication()}
        <div className="layout-overlay" onClick={this.closeSidenav}></div>
        <MessageBox show={this.state.isModalVisible}
          close={this.closeModal}
          confirm={this.state.modalConfirm}
          text={this.state.modalText}
          html={this.state.modalHtml}
          title={this.state.modalTitle}
          type={this.state.type}
          okText={this.state.modalOkText}
          closeText={this.state.modalCloseText}
        ></MessageBox>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          closeButton={<CloseButton />}
        />
      </div>
    )
  }
}

export default Layout;
