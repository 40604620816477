import React, { Component } from 'react'
import Router from './shared/Router'
import { connect } from 'react-redux'
import './App.scss'

class App extends Component {
  render() {
    return <Router />
  }
}

export default connect(null, {})(App)
