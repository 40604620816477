import React from 'react'
import loadable from '@loadable/component'
import pMinDelay from 'p-min-delay'
import Loader from './shared/Loader'
import Layout from './shared/layouts/Layout'
import LayoutBlank from './shared/layouts/LayoutBlank'

const lazy = (cb) => loadable(() => pMinDelay(cb(), 200), { fallback: <Loader /> })

export const DefaultLayout = Layout
export const titleTemplate = '%s - Sağlık Bakanlığı'
export const defaultRoute = '/uygulamalar'

export const defaultRoutes = [
  {
    path: '/',
    component: lazy(() => import('./pages/dashboards/Dashboard'))
  }, {
    path: '/settings',
    component: lazy(() => import('./pages/user/Settings'))
  }, {
    path: '/loginIndex',
    component: lazy(() => import('./pages/user/loginIndex'))
  }, {
    path: '/sign-in',
    layout: LayoutBlank,
    component: lazy(() => import('./pages/auth/signIn'))
  }, {
    path: '/sign-out',
    layout: LayoutBlank,
    component: lazy(() => import('./pages/auth/signOut'))
  }
]
