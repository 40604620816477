import ServiceBase from '../../service';

class DashboardService extends ServiceBase {
    constructor(){
        super('api/home');
    }
    loadApplications(callback) {
        super.get("get-portal-applications", callback);
    }
    getProfile(callback) {
        super.get("profile", callback);
    }
    getLogo(id) {
        return super.getFile(`get-logo/${id}`);
    }
    loadNotifications(callback) {
        super.get("get-portal-notifications", callback);
    }
}

export default new DashboardService();