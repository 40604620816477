import { SHOW_SUCCESS_MESSAGEBOX, SHOW_WARNING_MESSAGEBOX, SHOW_ERROR_MESSAGEBOX, SHOW_MESSAGEBOX, CLEAR_MESSAGEBOX } from '../actionTypes'
const getMessageBoxState = () => {
    let messageBox = {
        show: false,
        text: 'İçerik',
        html: '',
        title: 'Başlık',
        okText: 'Tamam',
        closeText: '',
        confirm: function () {

        }
    }
    return messageBox;
}
const initialState = getMessageBoxState();

function buildPayload(payload) {
    let result = { ...payload };
    if (!result.okText) {
        result.okText = initialState.okText;
    }
    if (!result.confirm) {
        result.confirm = initialState.confirm;
    }
    return result;
}

export default function (state = initialState, action) {
    if (action.type === SHOW_SUCCESS_MESSAGEBOX) {
        return buildPayload({ ...action.payload, show: true, type: "success" });
    }
    if (action.type === SHOW_WARNING_MESSAGEBOX) {
        return buildPayload({ ...action.payload, show: true, type: "warning" });
    }
    if (action.type === SHOW_ERROR_MESSAGEBOX) {
        return buildPayload({ ...action.payload, show: true, type: "danger" });
    }
    if (action.type === SHOW_MESSAGEBOX) {
        return buildPayload({ ...action.payload, show: true, type: "info" });
    }
    if (action.type === CLEAR_MESSAGEBOX) {
        return buildPayload({ ...action.payload, show: false });
    }
    return state;
}