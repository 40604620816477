import "./polyfills";
import "./automation";
import React from "react";
import ReactDOM from "react-dom";
import Popper from "popper.js";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { Provider } from "react-redux";
import store from "./store";

Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false;

const rootElement = document.getElementById("root");
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  rootElement
);

serviceWorker.unregister();