import React, { Component } from 'react'
import { ToastContainer } from 'react-toastify'

const CloseButton = ({ closeToast }) => (
  <button className="Toastify__close-button" type="button" aria-label="close"
    onClick={closeToast}>&times;</button>
)

class LayoutBlank extends Component {
  render() {
    return (
      <div>
        {this.props.children}
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          closeButton={<CloseButton />}
        />
      </div>
    )
  }
}

export default LayoutBlank
