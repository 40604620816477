import React, { Component } from "react";
import { Modal, Button, Table } from "react-bootstrap";

class CookieModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false
        }
    }

    componentDidMount() {
        this.setState({ show: true })
    }

    componentDidUpdate(previousProps, prevState) {
        if (prevState.show !== true) {
            this.setState({ show: true })
        }
    }

    onBack() {
        this.setState({ show: false });
        this.props.close();
    }

    render() {
        return (
            <React.Fragment>
                <Modal id="cookieModal" className="modal-sldie crud-dialog" size="xl" show={this.state.show} onHide={() => this.onBack()}>
                <Modal.Title as="h3" className="mt-3 ml-3">T.C. SAĞLIK BAKANLIĞI ORTAK GİRİŞ NOKTASI (OGN) UYGULAMASI ÇEREZ POLİTİKASI</Modal.Title>
                    <Modal.Body>
                        <p>Bu politika, Sağlık Bakanlığı tarafından sunulan web tabanlı ve mobil uygulamaların kimlik doğrulama işlemlerinin merkezi olarak gerçekleştirilmesi amacıyla hizmet veren Ortak Giriş Noktası (OGN) uygulamasında (https://ogn.saglik.gov.tr) yer alan çerez kullanım esasları hakkında kullanıcıları bilgilendirmek amacıyla hazırlanmıştır.</p>
                        <h3>1. ÇEREZ NEDİR?</h3>
                        <p>İnternet sitelerinin cihazlarınıza kaydettiği küçük boyutlu ve kullanıcıları tanımlamaya yarayan veri dosyalarıdır. Çerezler internet siteleri tarafından kullanıcıların tanımlanması ve onlara özel hizmet sunulması amacıyla kullanılır.</p>
                        <h3>2. OGN UYGULAMASI ÇEREZ KULLANIMI</h3>
                        <p>https://ogn.saglik.gov.tr/ internet sitesinde, kullanıcılara en iyi deneyimi sunmak için çerezler kullanılmaktadır.</p>
                        <h3>Hangi Tür Çerezler Kullanıyoruz?</h3>
                        <p>Oturum çerezleri, oturumun sürekliliğinin sağlanması amacıyla kullanılmaktadır.</p>
                        <p>Performans ve istatistik çerezleri, internet sitesini veya uygulamayı yönetmek için trafik ve/veya performans istatistiklerinin kullanılması, bu istatistiklerin üretilmesi sitenin veya uygulamanın düzgün çalışması ve dolayısıyla hizmetin sağlanması amacıyla kullanılmaktadır.</p>
                        <p>Söz konusu çerezler Kişisel Verilerin Korunması Kanunu’nun 5’inci maddesinin ikinci fıkrasının (f) bendi uyarınca “İlgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla, veri sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu olması” kapsamında işlenmektedir.</p>
                        <Table className="table-bordered table-hover" style={{ padding: '20px', textAlign: 'center' }}>
                            <thead>
                                <tr>
                                    <td>Çerez Sağlayıcı</td>
                                    <td>Çerez İsmi</td>
                                    <td>Çerez Amacı</td>
                                    <td>Çerez Türü</td>
                                    <td>Çerezlerin Tarayıcıda Tutulduğu Süre</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>google.com </td>
                                    <td>_gid*</td>
                                    <td>Google analytics </td>
                                    <td>Performans ve İstatistik Çerezi </td>
                                    <td>En fazla 1 gün</td>
                                </tr>
                                <tr>
                                    <td>google.com </td>
                                    <td>_ga*</td>
                                    <td>Google analytics </td>
                                    <td>Performans ve İstatistik Çerezi </td>
                                    <td>En fazla 1 yıl</td>
                                </tr>
                                <tr>
                                    <td>google.com </td>
                                    <td>_GRECAPTCHA</td>
                                    <td>Google Recaptcha </td>
                                    <td>Performans ve İstatistik Çerezi </td>
                                    <td>En fazla 6 ay</td>
                                </tr>
                                <tr>
                                    <td>ogn.saglik.gov.tr </td>
                                    <td>.AspNetCore.Antiforgery</td>
                                    <td>CSRF Güvenlik Token</td>
                                    <td>Oturum Çerezi </td>
                                    <td>Tarayıcı Oturumu Süresince</td>
                                </tr>
                                <tr>
                                    <td>ogn.saglik.gov.tr </td>
                                    <td>idsrv</td>
                                    <td>Kullanıcı Oturum Bilgisi</td>
                                    <td>Oturum Çerezi </td>
                                    <td>Tarayıcı Oturumu Süresince</td>
                                </tr>
                                <tr>
                                    <td>ogn.saglik.gov.tr </td>
                                    <td>idsrv.session</td>
                                    <td>Kullanıcı Oturum Bilgisi</td>
                                    <td>Oturum Çerezi </td>
                                    <td>Tarayıcı Oturumu Süresince</td>
                                </tr>
                            </tbody>
                        </Table>
                        <h3>3. ÇEREZ YÖNETİMİ</h3>
                        <p>İnternet sitemize erişim sağladığınız cihaz üzerinde çerez tutulmasını istemiyorsanız tarayıcınızın ayarları üzerinden çerez kullanımını engelleyebilir, sınırlayabilir ve silebilirsiniz. Bu tercihlerinizi sonradan değiştirmeniz de mümkündür.</p>
                        <p>İnternet sitemize farklı cihazlar üzerinden erişim sağlıyorsanız, erişim sağladığınız her bir cihaz ve tarayıcı üzerinde çerez kullanım ayarlarını tercihlerinize uygun olarak aşağıdaki bağlantılar üzerinden gerçekleştirebilirsiniz.</p>
                        <p>Kullanıcı olarak eğer çerez kullanımını önlemek istiyorsanız;</p>
                        <Table className="table-bordered table-hover">
                            <tbody>
                                <tr>
                                <td>Google Analytics</td>
                                    <td>
                                        <a target="_blank" href="https://tools.google.com/dlpage/gaoptout">
                                            https://tools.google.com/dlpage/gaoptout
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Google Chrome</td>
                                    <td>
                                        <a target="_blank" href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=tr">
                                            https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&hl=tr
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Internet Explorer</td>
                                    <td>
                                        <a target="_blank" href="https://support.microsoft.com/tr-tr/help/17442/windows-internet-explorer-delete-manage-cookies">
                                            https://support.microsoft.com/tr-tr/help/17442/windows-internet-explorer-delete-manage-cookies
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Mozilla Firefox</td>
                                    <td>
                                        <a target="_blank" href="https://support.mozilla.org/tr/kb/cerezleri-silme-web-sitelerinin-bilgilerini-kaldirma">
                                            https://support.mozilla.org/tr/kb/cerezleri-silme-web-sitelerinin-bilgilerini-kaldirma
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Opera</td>
                                    <td>
                                        <a target="_blank" href="https://help.opera.com/en/latest/web-preferences/">
                                            https://help.opera.com/en/latest/web-preferences/
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Safari</td>
                                    <td>
                                        <a target="_blank" href="https://support.apple.com/tr-tr/HT201265">
                                            https://support.apple.com/tr-tr/HT201265
                                        </a>
                                        &nbsp;&nbsp;
                                        <a target="_blank" href="https://support.apple.com/tr-tr/guide/safari/sfri11471/mac">
                                            https://support.apple.com/tr-tr/guide/safari/sfri11471/mac
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="default float-right mr-2" id="btnBack" onClick={() => this.onBack()}>Kapat</Button>
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
        )
    }
}

export default CookieModal