import Oidc from "oidc-client"
import store from '../store';
import { GET_USER_SESSION, GET_LDAP_USER } from '../store/actionTypes';

const config = {
  authority: process.env.REACT_APP_AUTHSERVER,
  client_id: "sso-portal",
  redirect_uri: process.env.REACT_APP_HOSTURL + "/sign-in",
  response_type: "code",
  scope: "openid profile email portal.login",
  post_logout_redirect_uri: process.env.REACT_APP_HOSTURL + "/sign-out",
};

const authenticate = (forceSignIn, callback) => {
  var mgr = new Oidc.UserManager(config);
  mgr.getUser().then(user => {
    if (forceSignIn || !user || user.expired) {
      localStorage.clear();
      mgr.signinRedirect();
      return;
    }
    localStorage.clear();
    let data = { displayName: user.profile.name, id_token: user.id_token, access_token: user.access_token };
    localStorage.setItem("session", JSON.stringify(data));
    const updateSessionData = {
      type: GET_USER_SESSION,
      payload: data,
    };
    store.dispatch(updateSessionData);
    store.dispatch({ type: GET_LDAP_USER, payload: user.profile.amr.includes("40000000-0000-0000-0000-000000000001") });
    callback({ signedIn: true });
  });
}

const logout = () => {
  localStorage.clear();
  sessionStorage.clear();
  var mgr = new Oidc.UserManager(config);
  mgr.signoutRedirect();
}

export default {
  authenticate: authenticate,
  logout: logout
};