import axios from 'axios';
import store from '../store';

const ax = axios.create({
    baseURL: process.env.REACT_APP_PORTALAPI,
    json: true,
    timeout: 30000,
    withCredentials: true
});

const get = url => {
    return ax.get(url, header());
};

const post = (url, data) => {
    return ax.post(url, data, header());
};

const put = (url, data) => {
    return ax.put(url, data, header());
};

const remove = (url) => {
    return ax.delete(url,  header());
};

const getFile = (url) => {
    return axios.create({
        baseURL: process.env.REACT_APP_PORTALAPI,
        timeout: 30000,
        withCredentials: true,
        responseType: "blob"
    }).get(url,  header());
};

const header = () => {
    let props = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
    };
    let token = getToken();
    if (token) {
        props.Authorization = `Bearer ${token}`;
    }

    return {
        headers: props
    }
};

const getToken = () => {
    var hasSession = localStorage.key('session')
    if (!hasSession) {
        return null;
    }
    let session = store.getState().session;
    let created = new Date(session.created);
    let lastValidaDate = new Date(created.getTime() + (session.lifeTime * 60000));
    if (lastValidaDate < new Date()) {
        localStorage.clear();
        return null;
    }
    if (session.access_token) {
        return session.access_token;
    }
};

let ajax = {
    get: get,
    getFile: getFile,
    post: post,
    delete: remove,
    put: put
}

export default ajax;