import { GET_USER_SESSION } from "../actionTypes";
const getUserSession = () => {
    let session = {
        id_token: "",
        access_token : "",
        displayName: ""
    }
    let s = localStorage.getItem("session");
    if (s) {
        session = JSON.parse(s);
    }
    return session;
}

const initialState = getUserSession()

export default function (state = initialState, action) {
    if (action.type === GET_USER_SESSION) {
        return action.payload;
    }
    return state;
}