import React, { Component } from 'react'
import CookieModal from './CookieModal';
import { Button } from 'react-bootstrap'

const modalState = {
    CookieModal: "cookieModal"
}

class LayoutCookie extends Component {
    constructor(props) {
        super(props)

        this.state = {
            cookie: true,
            modalState: "",
        }
    }

    prevent(e) {
        e.preventDefault()
    }

    closeCookie() {
        this.setState({ cookie: !this.state.cookie });
        sessionStorage.setItem('cookieAccepted', "1");
    }

    showCookieModal() {
        if (this.state.modalState === modalState.CookieModal) {
            return (<CookieModal close={() => { this.closeModal() }} />);
        }
    }

    cookieModal() {
        this.setState({ modalState: modalState.CookieModal })
    }

    closeModal() {
        this.setState({ modalState: null })
    }

    renderCookie() {
        return (
            <div style={{ position: 'fixed', bottom: '0', zIndex: '1031', width: '-webkit-fill-available' }}>
                <div style={{ backgroundColor: '#404853', height: '52px' }}>
                    <div style={{ fontFamily: 'Arial', fontSize: '13px', textAlign: 'center', color: 'white', padding: '10px' }}>
                        Sitemizde sizlere daha iyi hizmet verebilmek için gizliliğe uygun şekilde çerezler kullanmaktayız. Çerez politikamızı inceleyebilirsiniz.
                        &nbsp;
                        &nbsp;
                        <a href='#' onClick={() => this.cookieModal()} style={{ color: 'red', textDecoration: 'underline' }}>Çerez Politikası</a>
                        &nbsp;&nbsp;
                        <Button variant="primary mr-2 btn-xs" onClick={() => this.closeCookie()} >Tamam</Button>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        if (sessionStorage.getItem("cookieAccepted") !== "1" && this.state.cookie) {
            return (
                <React.Fragment>
                    {this.showCookieModal()}
                    {this.renderCookie()}
                </React.Fragment>
            )
        }
        return (
            null
        )
    }
}

export default LayoutCookie;
