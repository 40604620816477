(function () {
  window.automation = window.automation || {};
  window.automation.columnHasValue = function (table, columnIndex, value) {
    let tab = document.getElementById(table);
    let n = tab.rows.length;
    if (columnIndex < 0) {
      return false;
    }
    for (let i = 0; i < n; i++) {
      let tr = tab.rows[i];
      if (tr.cells.length > columnIndex) {
        let td = tr.cells[columnIndex];
        if (td.innerText === value) {
          return true;
        }
      }
    }
    return false;
  };

  window.automation.clickTableButton = function (
    tableName,
    columnIndex,
    columnValue,
    btnClass
  ) {
    let table = document.getElementById(tableName);
    if (columnIndex < 0) {
      return;
    }
    for (let i = 0; i < table.rows.length; i++) {
      const row = table.rows[i];
      if (row.cells.length > columnIndex) {
        const column = row.cells[columnIndex];
        if (column.innerText === columnValue) {
          const pButton = Array.from(
            row.cells[row.cells.length - 1].children
          ).find((f) => f.classList.contains(btnClass));
          if (pButton) {
            pButton.click();
          }
          else {
            alert(`[AUTOMATION : ] ${btnClass} could not be found in table "${tableName}"`);
          }
        }
      }

    }
  }
  window.automation.selectRow = function (tableName, columnIndex, columnValue) {
    let table = document.getElementById(tableName);
    if (columnIndex < 0) {
      return;
    }
    for (let i = 0; i < table.rows.length; i++) {
      const row = table.rows[i];
      if (row.cells.length > columnIndex) {
        const column = row.cells[columnIndex];
        if (column.innerText === columnValue) {
          let checkBox = row.cells[0];
          if (checkBox) {
            checkBox.click();
          }
        }
      }
    }
  };

  window.automation.selectRowByColumnValues = function (tableName, columnValues) {
    if (!Array.isArray(columnValues)) {
      alert("Kolon değerleri dizi olmalıdır");
      return;
    }
    let table = document.getElementById(tableName);
    for (let i = 0; i < table.rows.length; i++) {
      let matched = true;
      const row = table.rows[i];
      for (let j = 0; j < columnValues.length; j++) {
        if (row.cells[j + 1].innerText !== columnValues[j]) {
          matched = false;
          break;
        }
      }
      if (matched) {
        let checkBox = row.cells[0];
        if (checkBox) {
          checkBox.click();
        }
      }
    }
  };
})();
//IIFE -> Immediately Invoked Function Expressions
