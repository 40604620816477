import React, { Component } from 'react'

class LayoutFooter extends Component {
  prevent(e) {
    e.preventDefault()
  }

  render() {
    return (
      <nav className="layout-footer footer bg-danger-darker footer-dark">
        <div className="container-fluid d-flex flex-wrap justify-content-between text-center container-p-x pb-3">
          <div className="pt-3">
            <span className="footer-text font-weight-bolder">Sağlık Bakanlığı</span> ©
          </div>
        </div>
      </nav>
    )
  }
}

export default LayoutFooter;
